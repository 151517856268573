import React, { useEffect, useState } from "react";
import io from "socket.io-client";

import { BandAdd } from "./components/BandAdd";
import { BandList } from "./components/BandList";
import { Routing } from "./Router/Routing";

const conectSocketServer = () => {
  const socket = io("http://localhost:8080", {
    transports: ["websocket"],
    credentials: true,
  });
  return socket;
};

function App() {
  const [socket] = useState(conectSocketServer);
  const [online, setOnline] = useState(false);
  const [bands, setBands] = useState([]);

  useEffect(() => {
    setOnline(socket.connected);
  }, [socket]);

  useEffect(() => {
    socket.on("connect", () => {
      setOnline(true);
    });
  }, [socket]);

  useEffect(() => {
    socket.on("disconnect", () => {
      setOnline(false);
    });
  }, [socket]);

  useEffect(() => {
    socket.on("current-bands", (bands) => {
      setBands(bands);
    });
  }, [socket]);

  const votar = (id) => {
    socket.emit("votar-band", id);
  };
  const rename = (id, nombre) => {
    socket.emit("rename-band", { id, nombre });
  };
  const deleteBand = (id) => {
    socket.emit("delete-band", id);
  };
  const addBand = (nombre) => {
    socket.emit("add-band", nombre);
  };

  return (
    <div >
      <Routing/>
  
{/*       <div className="alert">
        <p>
          Service Status:
          {online ? (
            <span className="text-success"> Online</span>
          ) : (
            <span className="text-danger"> Offline</span>
          )}
        </p>
      </div>

      <h1>BandNames</h1>
      <hr />

      <div className="row">
        <div className="col-8">
          <BandList
            deleteBand={deleteBand}
            rename={rename}
            data={bands}
            votar={votar}
          />
        </div>
        <div className="col-4">
          <BandAdd addBand={addBand} />
        </div>
      </div> */}

    </div>
  );
}

export default App;
