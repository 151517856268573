import React, { useState, useRef, useEffect } from "react";
import "./chat1.css";
import "./chat2.css";

export const ChatGPT = ({ messages, enviarMensajeGPT }) => {
  const [mensaje, setMensaje] = useState("");
  const [codigo, setCodigo] = useState("");
  const [chatStyle, setChatStyle] = useState("chat1");
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "mensaje") {
      setMensaje(value);
    } else if (name === "codigo") {
      setCodigo(value);
    }
    ajustarAnchoCampo();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Verificar si el mensaje no está vacío antes de enviarlo
    if (mensaje.trim() !== "") {
        enviarMensajeGPT(  "user", mensaje,codigo); // Enviar mensaje al bot GPT con el rol de usuario
      setMensaje("");
      ajustarAnchoCampo();
    }
  };

  const ajustarAnchoCampo = () => {
    if (inputRef.current) {
      inputRef.current.style.width = "auto";
      inputRef.current.style.width = `${inputRef.current.scrollWidth}px`;
    }
  };

  const toggleChatStyle = () => {
    setChatStyle(chatStyle === "chat1" ? "chat2" : "chat1");
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div id="chat-container">
      <section className={`${chatStyle} chat-container`}>
        <div className={`${chatStyle} chat-messages`}>
          <ul>
            {messages.map((message, index) => (
              <li key={index} className={`${chatStyle} chat-message`}>
                {message.content}
              </li>
            ))}
            <div ref={messagesEndRef} />
          </ul>
        </div>
      </section>
      <div id="form">
        <button
          style={{
            border: "transparent",
            color: "transparent",
            backgroundColor: "transparent",
            cursor: "pointer",
          }}
          onClick={toggleChatStyle}
          onMouseOver={(e) => (e.target.style.color = "#007bff")}
          onMouseOut={(e) => (e.target.style.color = "transparent")}
        >
          Cambiar Estilo
        </button>

        <form onSubmit={handleSubmit} className={`${chatStyle} chat-form`}>
          <input
            ref={inputRef}
            type="text"
            name="mensaje"
            value={mensaje}
            onChange={handleChange}
            className={`${chatStyle} chat-input`}
            placeholder="Mensaje..."
          />
          <input
            type="text"
            name="codigo"
            value={codigo}
            onChange={handleChange}
            className={`${chatStyle} chat-input-codigo`}
            placeholder="Código..."
          />
          <button type="submit" className={`${chatStyle} chat-button`}>
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
};
