import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Chat } from "../components/Chat";
import io from "socket.io-client";
import { ChatGPT } from "../components/ChatGPT";

const connectSocketServer = () => {
  const socket = io("http://161.132.55.14:8080/", {
    transports: ["websocket"],
    credentials: true,
  });
  return socket;
};

export const Routing = () => {
  const [socket] = useState(connectSocketServer);
  const [online, setOnline] = useState(false);
  const [bands, setBands] = useState([]);
  const [messages, setMessages] = useState([]);
  const [messagesGPT, setMessagesGPT] = useState([]);
  useEffect(() => {
    setOnline(socket.connected);
  }, [socket]);

  useEffect(() => {
    socket.on("connect", () => {
      setOnline(true);
    });

    socket.on("disconnect", () => {
      setOnline(false);
    });

    socket.on("current-bands", (bands) => {
      setBands(bands);
    });

    socket.on("current-messages", (messages) => {
      setMessages(messages);
    });
    socket.on("current-messages", (messages) => {
      setMessages(messages);
    });
    socket.on("current-messages-gpt", (messagesGPT) => {
      setMessagesGPT(messagesGPT);
    });
    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("current-bands");
      socket.off("current-messages");
    };
  }, [socket]);

  const votar = (id) => {
    socket.emit("votar-band", id);
  };

  const rename = (id, nombre) => {
    socket.emit("rename-band", { id, nombre });
  };

  const deleteBand = (id) => {
    socket.emit("delete-band", id);
  };

  const addBand = (nombre) => {
    socket.emit("add-band", nombre);
  };

  const enviarMensaje = (mensaje) => {
    socket.emit("enviar-mensaje", mensaje);
  };
  const enviarMensajeGPT = (mensaje,user,codigo) => {
    socket.emit("enviar-mensaje-gpt", mensaje,user,codigo);
  };

  return (
    <BrowserRouter>
      <Routes>
        {/* Ruta para el componente Chat */}
        <Route
          path="/chat"
          element={<Chat enviarMensaje={enviarMensaje} messages={messages} />}
        />
        <Route
          path="/chatgpt"
          element={<ChatGPT enviarMensajeGPT={enviarMensajeGPT} messages={messagesGPT} />}
        />
      </Routes>
    </BrowserRouter>
  );
};
