import React, { useState, useRef, useEffect } from "react";
import "./chat1.css";
import "./chat2.css";

export const Chat = ({ messages, enviarMensaje }) => {
  const [mensaje, setMensaje] = useState("");
  const [chatStyle, setChatStyle] = useState("chat1");
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);

  const handleChange = (event) => {
    setMensaje(event.target.value);
    ajustarAnchoCampo();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Verificar si el mensaje no está vacío antes de enviarlo
    if (mensaje.trim() !== "") {
      enviarMensaje(mensaje);
      setMensaje("");
      ajustarAnchoCampo();
    } else {
    }
  };

  const ajustarAnchoCampo = () => {
    if (inputRef.current) {
      inputRef.current.style.width = "auto";
      inputRef.current.style.width = `${inputRef.current.scrollWidth}px`;
    }
  };

  const toggleChatStyle = () => {
    setChatStyle(chatStyle === "chat1" ? "chat2" : "chat1");
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div id="chat-container">
      <section className={`${chatStyle} chat-container`}>
        <div className={`${chatStyle} chat-messages`}>
          <ul>
            {messages.map((message) => (
              <li key={message.id} className={`${chatStyle} chat-message`}>
                {message.content}
              </li>
            ))}
            <div ref={messagesEndRef} />
          </ul>
        </div>
      </section>
      <div id="form">
        <button
          style={{
            border: "transparent",
            color: "transparent",
            backgroundColor: "transparent",
            cursor: "pointer", // Hace que el cursor cambie a una mano cuando se pasa sobre el botón
          }}
          onClick={toggleChatStyle}
          onMouseOver={(e) => (e.target.style.color = "#007bff")} // Cambia el color del texto al azul cuando se pasa el mouse sobre el botón
          onMouseOut={(e) => (e.target.style.color = "transparent")} // Restablece el color del texto a transparente cuando se quita el mouse del botón
        >
          Cambiar Estilo
        </button>

        <form onSubmit={handleSubmit} className={`${chatStyle} chat-form`}>
          <input
            ref={inputRef}
            type="text"
            value={mensaje}
            onChange={handleChange}
            className={`${chatStyle} chat-input`}
            placeholder="..."
            autoComplete="off"
          />
          <button type="submit" className={`${chatStyle} chat-button`}>
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
};
